const carsInfo = [
  {
    id: "Kia-ev6",
    car: "KIA EV6",
    model: "GT-Line e-AWD增程版",
    size: "4,695 mm X 1,890 mm X 1,550 mm",
    weight: "2,107 kg",
    capacity: "77.4",
    range: "496",
    rangeSrc: "WLTP",
    hp: "325 ps / rpm",
    zeroOneHundred: "5.2 秒",
    maxSpeed: "260 km/h",
    transmission: "雙馬達四輪驅動",
    electricalSystem: "800",
    acSpec: "J1772 (Type1)",
    dcSpec: "CCS1",
    dcMaxInput: "240",
    tenToEightyPercent: "18",
    dcRangePlus: "350",
    acRangePlusPercent: "3",
    acRangePlus: "14",
    price: "219.9",
    description:
      "Kia EV6原廠直流充電最大功率240 kW，在U-POWER 超高速充電站內白色500A液冷槍、及總功率360kW充電設備兩者兼備下，10%充電至 80%，僅需18分鐘。",
  },
  {
    id: "Hyundai-ioniq6",
    car: "Hyundai Ioniq6",
    model: "EV600 Performance",
    size: "4,855 mm X 1,880 mm X 1,495 mm",
    weight: "2,062 kg",
    capacity: "77.4",
    range: "618",
    rangeSrc: "NEDC",
    rangeWLTP: "519",
    hp: "321 ps / rpm",
    zeroOneHundred: "5.1 秒",
    maxSpeed: "185 km/h",
    transmission: "雙馬達四輪驅動",
    electricalSystem: "800",
    acSpec: "J1772 (Type1)",
    dcSpec: "CCS1",
    dcMaxInput: "240",
    tenToEightyPercent: "18",
    dcRangePlus: "430",
    acRangePlusPercent: "3",
    acRangePlus: "18",
    price: "219.9",
    description:
      "Hyundai Ioniq 6原廠直流充電最大功率240k W，在U-POWER 超高速充電站內白色500A液冷槍、及總功率360kW充電設備兩者兼備下，10%充電至 80%，僅需18分鐘。",
  },
  {
    id: "VW-idbuzz",
    car: "VW ID.Buzz",
    model: "短軸版",
    size: "4,712 mm X 1,985 mm X 1,937 mm",
    weight: "2,474 kg",
    capacity: "82",
    range: "423",
    rangeSrc: "WLTP",
    hp: "204 ps / rpm",
    zeroOneHundred: "10.2 秒",
    maxSpeed: "145 km/h",
    transmission: "單馬達後輪驅動",
    electricalSystem: "400",
    acSpec: "Type2",
    dcSpec: "CCS2",
    dcMaxInput: "170",
    tenToEightyPercent: "30",
    dcRangePlus: "300",
    acRangePlusPercent: "4",
    acRangePlus: "17",
    price: "268",
    description:
      "VW ID.Buzz所配置的原廠充電峰值功率為170kW ，在U-POWER 超高速充電站內白色500A液冷槍、及總功率360kW充電設備兩者兼備下，5%充電至 80%，僅需30分鐘。",
  },
  {
    id: "mb-eqesuv",
    car: "Mercedes Benz EQE SUV",
    model: "350 4Matic",
    size: "4,879 mm X 1,918 mm X 1,685 mm",
    weight: "2,580 kg",
    capacity: "89",
    range: "549",
    rangeSrc: "WLTP",
    hp: "292 ps / rpm",
    zeroOneHundred: "6.6 秒",
    maxSpeed: "210 km/h",
    transmission: "雙馬達四輪驅動",
    electricalSystem: "400",
    acSpec: "J1772 (Type1)",
    dcSpec: "CCS1",
    dcMaxInput: "170",
    tenToEightyPercent: "31",
    dcRangePlus: "380",
    acRangePlusPercent: "4",
    acRangePlus: "22",
    price: "358",
    description:
      "Mercedes-Benz EQE SUV 350 4Matic原廠直流充電最大功率240 kW ，在U-POWER 超高速充電站內白色500A液冷槍、及總功率360kW充電設備兩者兼備下，10%充電至 80%，僅需31分鐘。",
  },
  {
    id: "bmw-ix-xdrive40",
    car: "BMW iX",
    model: "xDrive40",
    size: "4,953 mm X 1,967 mm X 1,695 mm",
    weight: "2,365 kg",
    capacity: "76.6",
    range: "430",
    rangeSrc: "WLTP",
    hp: "326 ps / rpm",
    zeroOneHundred: "6.1 秒",
    maxSpeed: "200 km/h",
    transmission: "雙馬達四輪驅動",
    electricalSystem: "400",
    acSpec: "J1772 (Type1)",
    dcSpec: "CCS1",
    dcMaxInput: "150",
    tenToEightyPercent: "31",
    dcRangePlus: "301",
    acRangePlusPercent: "4",
    acRangePlus: "19",
    price: "339",
    description:
      "BMW iX xDrive40原廠直流充電最大功率150 kW，在U-POWER 超高速充電站內白色500A液冷槍、及總功率360kW充電設備兩者兼備下，10%充電至 80%，僅需 31 分鐘。",
  },
  {
    id: "bmw-ix-xdrive50",
    car: "BMW iX",
    model: "xDrive50",
    size: "4,953 mm X 1,967 mm X 1,696 mm",
    weight: "2,510 kg",
    capacity: "111.5",
    range: "651",
    rangeSrc: "WLTP",
    hp: "523 ps / rpm",
    zeroOneHundred: "4.6 秒",
    maxSpeed: "200 km/h",
    transmission: "雙馬達四輪驅動",
    electricalSystem: "400",
    acSpec: "J1772 (Type1)",
    dcSpec: "CCS1",
    dcMaxInput: "195",
    tenToEightyPercent: "35",
    dcRangePlus: "456",
    acRangePlusPercent: "3",
    acRangePlus: "20",
    price: "439",
    description:
      "BMW iX xDrive50原廠直流充電最大功率195 kW，在U-POWER 超高速充電站內白色500A液冷槍、及總功率360kW充電設備兩者兼備下，10%充電至 80%，僅需 35 分鐘。",
  },
];

export default carsInfo;
