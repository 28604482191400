<template>
  <div id="carsIntroduction-menu-wrapper">
    <div class="main-icon-container">
      <img src="../../assets/images/main-logo.png" alt="" />
    </div>
    <div class="car-card-container">
      <router-link
        :to="{
          name: 'CarsIntroduction-detail',
          params: { car: car.id },
        }"
        class="car-card"
        v-for="car in carsInfo"
        :key="car.id"
      >
        <div class="cover-box">
          <img
            :src="require('../../assets/images/' + car.id + '.jpg')"
            alt="thumb"
          />

          <div class="price-box">
            <span>{{ car.price + " " }}</span>
            <span>萬</span>
          </div>
        </div>
        <div class="info-box">
          <div class="title">{{ car.car }}</div>
          <div class="subtitle">{{ car.model }}</div>
          <div class="spec-box">
            <div>
              <span>{{ car.capacity + " kWh" }}</span>
              <span>電池容量</span>
            </div>
            <div class="divider"></div>
            <div>
              <span>{{ car.dcMaxInput + " kW" }}</span>
              <span>充電速度</span>
            </div>
            <div class="divider"></div>
            <div>
              <span>{{ car.tenToEightyPercent + "分鐘" }}</span>
              <span v-if="car.id !== 'VW-idbuzz'">10~80% 充電</span>
              <span v-else>5~80% 充電</span>
            </div>
          </div>
          <div class="spec-box">
            <div>
              <span>{{ car.range + " km" }}</span>
              <span>最大續航力</span>
            </div>
            <div class="divider"></div>
            <div>
              <span>{{ car.zeroOneHundred }}</span>
              <span>0-100 km/h</span>
            </div>
            <div class="divider"></div>
            <div>
              <span>{{ car.electricalSystem + "V" }}</span>
              <span>電力系統</span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import carsInfo from "./carsInfo";

export default {
  name: "CarsIntroduction-menu",
  data() {
    return {
      carsInfo: carsInfo,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    });
  },
};
</script>

<style lang="scss" scoped>
#carsIntroduction-menu-wrapper {
  padding: 1.5rem;

  max-width: 430px;
  margin: auto;
  .main-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding-bottom: 1.5rem;
    img {
      width: 100px;
      height: auto;
    }
  }
  .car-card-container {
    width: auto;
    .car-card {
      display: block;
      margin-bottom: 1.5rem;
      background: white;
      border-radius: 1.5rem;
      &:focus {
        border: 3px solid $primary;
      }
      .cover-box {
        position: relative;
        width: 100%;
        border-radius: 1.5rem;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
        }
        .price-box {
          position: absolute;
          right: 0.75rem;
          bottom: 0.75rem;
          padding: 0.75rem;
          background-color: rgba(0, 0, 0, 0.75);
          border-radius: 0.75rem;
          color: white;
          font-weight: 500;
          span:nth-child(1) {
            font-size: 24px;
          }
          span:nth-child(2) {
            font-size: 15px;
          }
        }
      }
      .info-box {
        background: white;
        padding: 1rem;
        padding-bottom: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
        overflow: hidden;
        text-align: center;
        .title {
          font-size: 24px;
          font-weight: 500;
          color: black;
        }
        .subtitle {
          font-size: 15px;
          color: rgb(135, 135, 135);
          margin: 0.5rem 0 1rem 0;
        }
        .spec-box {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;
          div:not(.divider) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // flex-grow: 1;
            min-width: 33%;
            gap: 0.5rem;
            span:nth-child(1) {
              font-size: 20px;
              font-weight: 500;
              color: black;
            }
            span:nth-child(2) {
              font-size: 12px;
              color: hsla(0, 0%, 53%, 1);
            }
          }
          .divider {
            width: 1px;
            min-width: 1px;
            height: 24px;
            background: hsla(0, 0%, 53%, 1);
          }
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
